@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");

@font-face {
  font-family: "FuturaLT-regular";
  src:
    url("../src/assets/fonts/FuturaLT.ttf") format("ttf"),
    url("../src/assets/fonts/FuturaLT.woff") format("woff"),
    url("../src/assets/fonts/FuturaLT.woff2") format("woff2");
  font-weight: 400;
  font-display: auto;
  font-style: normal;
}
@font-face {
  font-family: "FuturaLT-book";
  src:
    url("../src/assets/fonts/FuturaLT-Book.woff") format("woff"),
    url("../src/assets/fonts/FuturaLT-Book.woff2") format("woff2");
  font-weight: 400;
  font-display: auto;
  font-style: normal;
}
@font-face {
  font-family: "FuturaLT-bold";
  src:
    url("../src/assets/fonts/FuturaLT-Bold.ttf") format("ttf"),
    url("../src/assets/fonts/FuturaLT-Bold.woff") format("woff"),
    url("../src/assets/fonts/FuturaLT-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: auto;
  font-style: normal;
}

@layer base {
  * {
    @apply font-futura;
  }
  .b-shadow {
    box-shadow: "0px 4px 6px rgba(0, 0, 0, 0.05)";
  }
  .btn-gradient {
    background: linear-gradient(3.24deg, #ff1d0c -1.08%, #ff402c 97.29%);
  }
  .border-light {
    border-color: #1f1f1f;
  }
  .service-image-gradient::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    z-index: 1;
    height: 100%;
    width: 100%;
  }
  .btn {
    @apply font-futura font-bold tracking-[2px] leading-5 text-sm text-white uppercase flex justify-center items-center p-4 border-2 border-white;
  }
  .tabBtn {
    @apply bg-transparent rounded-lg border-2 px-4 py-3 h-fit w-fit text-sm uppercase font-bold font-futuraBold tracking-[2px] hover:bg-danger hover:text-white hover:border-danger;
  }
}

body {
  overflow-x: hidden;
  color: #333333;
}

@layer components {
  .b-shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }

  /* Owl Customization */
  .owl-tamaro .owl-prev,
  .owl-tamaro .owl-next {
    @apply absolute top-1/2 z-10 -translate-y-[80%];
  }
  .owl-tamaro-children .owl-prev,
  .owl-tamaro-children .owl-next {
    @apply absolute top-36 z-10 -translate-y-[80%];
  }
  .owl-tamaro.owl-instagram-widget .owl-prev,
  .owl-tamaro.owl-instagram-widget .owl-next {
    @apply translate-y-[-4rem];
  }
  .owl-tamaro .owl-prev span,
  .owl-tamaro .owl-next span {
    @apply text-[22px] text-white border-4 border-white rounded-[50%] px-[10px] font-black;
  }
  .owl-tamaro .owl-theme .owl-nav {
    margin-top: 0;
  }
  .owl-tamaro .owl-theme .owl-nav {
    margin-top: 0;
  }
  .owl-tamaro.owl-theme .owl-nav [class*="owl-"]:hover {
    background-color: transparent !important;
  }
  .owl-tamaro.owl-carousel .owl-stage-outer {
    overflow: visible !important;
  }
  .owl-tamaro.owl-carousel .owl-dots .active > span {
    background-color: #f9b122 !important;
  }

  /* subpage slider customization */
  .owl-tamaro.owl-custom {
    overflow: hidden;
  }
  .owl-tamaro .owl-prev {
    @apply left-[24px];
  }
  .owl-tamaro .owl-next {
    @apply right-[24px];
  }

  @media screen and (min-width: 320px) {
    .pitches-table tbody tr:first-child td {
      padding-top: 0px;
    }
    .owl-navigation .owl-prev {
      @apply left-[50px] translate-y-[40%];
    }
    .owl-navigation .owl-next {
      @apply right-[50px] translate-y-[40%];
    }
  }

  @media screen and (min-width: 576px) {
    .owl-navigation .owl-prev {
      @apply left-[90px] -translate-y-[5%];
    }
    .owl-navigation .owl-next {
      @apply right-[90px] -translate-y-[5%];
    }
  }

  /* Hide Arrow */
  @media screen and (min-width: 767px) {
    .hide-prev-arrow .owl-nav .owl-prev {
      display: none !important;
    }
    .hide-next-arrow .owl-nav .owl-next {
      display: none !important;
    }
  }
  @media screen and (min-width: 992px) {
    .pitches-table tbody tr:first-child td {
      padding-top: 50px;
    }
    .owl-navigation .owl-prev {
      @apply left-[100px] -translate-y-[5%];
    }
    .owl-navigation .owl-next {
      @apply right-[100px] -translate-y-[5%];
    }
  }
}

/* Structure format */
.structure-format strong {
  @apply font-futuraBold;
}
.structure-format ul {
  list-style: disc;
  margin: 24px 0 24px 24px;
  display: block;
}
.structure-format ol li,
.structure-format ul li {
  margin-bottom: 5px;
}
.structure-format blockquote {
  padding: 20px;
  background-color: rgb(250, 250, 250);
  border-left: 5px solid rgb(87, 87, 87);
  margin: 20px 0;
}
.structure-format pre {
  margin: 20px 0;
  padding: 20px;
  background-color: rgb(233 246 255 / 67%);
}
.structure-format hr {
  margin: 10px 0;
  border: 1px solid #e2e2e2;
}
.structure-format h1,
h2,
h3,
h4,
h5,
h6,
h1 > strong,
h2 > strong,
h3 > strong,
h4 > strong,
h5 > strong,
h6 > strong {
  @apply text-base font-bold font-futuraBold;
}
.structured-para p {
  @apply font-normal font-futuraBook text-base leading-6;
}

.structure-breadcrumb p {
  margin-bottom: 8px;
}

/* privacy policy page styles */
.privacy-policy-cookie-script-table {
  overflow-x: auto;
  padding: 1rem 0;
}

.privacy-policy-cookie-script-table table {
  margin: 1rem 0;
}

.privacy-policy-cookie-script-table table tbody tr td {
  font-family: "FuturaLT-regular";
  font-size: 15px;
  line-height: 1.5rem;
}

.privacy-policy-cookie-script-table table tbody tr td a {
  text-decoration: underline;
}

.structured-text p {
  margin: 0.75rem 0;
}

.structured-text h3 {
  margin: 1rem 0;
}

.structured-text :is(h1, h2, h3, h4, h5, h6) {
  margin: 1rem 0;
}

.structured-text ul {
  list-style: disc;
  padding-left: 1.5rem;
  margin: 1rem 0;
}

/* map styles */
.map-icon-box {
  cursor: pointer;
}

@keyframes jumpAnimation {
  0%,
  100% {
    transform: translateY(0);
    animation-timing-function: ease;
  }
  50% {
    transform: translateY(-25%);
    animation-timing-function: ease;
  }
}

/* The Modal (background) */
.modal {
  position: fixed;
  z-index: 50;
  /* padding-top: 100px; */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.9);
}

/* Add Animation */
.modal-content .caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.close {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}

/* lightbox image styles */
.yarl__button:disabled {
  display: none;
}
.yarl__slide_image {
  cursor: grab;
}

/* swiper custom styles */
.swiper-main-custom .swiper > .swiper-wrapper {
  margin-bottom: 15px;
}

.swiper-main-custom .custom-wrapper.swiper > .swiper-wrapper {
  margin-bottom: 50px;
}
.swiper-pagination-bullet-active {
  background-color: #e35219 !important;
}

.swiper-main-custom .swiper > .swiper-wrapper .swiper-wrapper {
  margin-bottom: 0px;
}

/*  */
.owl-centered-carousel {
  padding: 90px 0px 62px;
}
.centered-images {
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}
.owl-centered-carousel.owl-carousel .owl-item.active.center {
  z-index: 99999;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.owl-centered-carousel.owl-carousel .owl-item {
  transform: scale(1.2, 0.8);
  transition: all 0.5s;
}
.owl-centered-carousel.owl-carousel .active {
  transform: scale(0.8);
  transition: all 0.5s;
}

.owl-centered-carousel .owl-nav .owl-prev {
  @apply left-[100px];
}
.owl-centered-carousel .owl-nav .owl-next {
  @apply right-[100px];
}

.partner-slider.owl-carousel .owl-item {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.instagram-post-prev,
.instagram-post-next {
  position: absolute;
  bottom: 16px;
}

.instagram-post-prev {
  left: 50%;
  transform: translateX(-120%);
}

.instagram-post-next {
  right: 50%;
  transform: translateX(120%);
}

.pitches-table tbody tr td {
  padding: 25px 0px;
}

.pitches-table thead tr th,
tbody tr td {
  @apply text-xs leading-[19px] tracking-[0.2px];
}

.pitches-table thead tr th:not(:first-child) {
  padding: 0 18px;
  min-width: max-content;
}

.pitches-table thead tr th,
tbody tr td {
  @apply font-futuraBold;
}

.pitches-price-section h5 {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.pitches-price-section .font-normal {
  font-weight: 400;
  font-family: "FuturaLT-regular";
  display: flex;
  align-items: center;
  gap: 8px;
}

.arrow ::after {
  content: "";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 8%;
  margin-left: 0px;
  border-width: 12px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.custom-range-disabled input[type="range"]::-webkit-slider-thumb {
  background-color: #e2e2e2;
  cursor: auto;
}

.slider__track,
.slider__range {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
  width: 100%;
}

.slider__track {
  background-color: #ced4da;
  z-index: 1;
}

.slider__range {
  background-color: #f9b122;
  z-index: 2;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  outline: none;
  width: 100%;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
